<template>
  <div>
    <a-card>
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="0">
            <a-col :md="6" :sm="24">
              <a-form-item label="门店编号:">
                <a-input
                  v-model="queryParam.facilityCode"
                  allowClear
                  placeholder="请输入门店编号"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="门店名称:">
                <a-input
                  v-model="queryParam.facilityName"
                  allowClear
                  placeholder="请输入门店名称"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24" style="text-align: right">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="dataSearch">查询</a-button>
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        rowKey="facilityId"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        @change="changePageSize"
        style="margin-bottom: 24px"
      >
        <span
          slot="name"
          slot-scope="text, record"
          :class="record.name != '' ? '' : 'red'"
        >
          {{ !!record.companyEntityId ? record.name : "尚未配置" }}
        </span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <template>
            <a @click="editFinContract(record)">{{
              !!record.companyEntityId ? "修改" : "配置"
            }}</a
            >&nbsp;&nbsp;
          </template>
        </span>
      </a-table>
    </a-card>
    <!-- 弹窗设置 -->
    <a-modal
      title="公司签约主体配置"
      :width="1080"
      :visible="viewFinContract"
      :confirmLoading="confirmLoading"
      destroyOnClose
      @ok="handleFinContract"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form
          layout="inline"
          :form="form"
          style="line-height: 0;padding: 0.3em;"
          class="finContractEditnew"
        >
          <a-row :gutter="0">
            <a-col>
              <a-form-item label="门店编号：">
                <span>{{ taxDetail.facilityCode }}</span>
              </a-form-item>
              <a-form-item label="门店名称：">
                <span>{{ taxDetail.facilityName }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="0">
            <a-col>
              <a-form-item label="签约公司主体">
                <a-select
                  style="width: 500px"
                  v-model="taxDetail.companyEntityId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in companyEntityList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
  
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "contractSubjectConfig",
  data() {
    return {
      queryParam: {},
      data: [],
      pagination: defaultPagination(() => {}),
      columns: [
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "id",
          fixed: "left"
        },
        {
          title: "签约公司主体",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          width: 140,
          fixed: "right"
        }
      ],
      confirmLoading: false,
      // viewFinContract
      viewFinContract: false,
      // thistaxDetail
      taxDetail: {},
      form: this.$form.createForm(this),
      current: 1,
      pageSize: 20,
      // getListCompanyEntity
      companyEntityList: []
    };
  },
  created() {
    this.init();
    this.getListCompanyEntity();
    Bus.$on("getTarget", target => {
      this.init();
      this.getListCompanyEntity();
    });
  },
  methods: {
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "signEntity/list",
        withCredentials: true,
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        this.data = res.data.rows;
        if (res.data.rows) {
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    getListCompanyEntity(current, pageSize) {
      // 查询公司主体列表
      // /signEntity/listCompanyEntity
      // 参数：name-公司主体名称、usc-企业统一社会信用代码、page、rows
      axios({
        url:   this.$baseUrl + "signEntity/listCompanyEntity",
        method: "GET",
        params: {
          rows: 20000,
          page: 1
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.companyEntityList = res.data.rows;
          }
        })
        .catch(error => {
          this.$message.error(error.message);
        });
    },
    dataSearch() {
      this.init();
    },
    // 换页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init(this.current, this.pageSize);
    },
    // handleFinContract
    handleFinContract() {
      this.viewFinContract = true;
      let obj = {};
      if (!this.taxDetail.companyEntityId) {
        this.$message.error("签约公司主体有误！");
        return;
      }
      axios({
        url:   this.$baseUrl + "signEntity/updateSignEntity",
        method: "POST",
        data: {
          ...this.taxDetail
        }
      })
        .then(res => {
          this.confirmLoading = false;
          if (res.data.success) {
            this.$message.success("保存成功！");
            this.viewFinContract = false;
            this.init(this.current, this.pageSize);
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.confirmLoading = false;
          this.$message.error(error.message);
        });
    },
    // 表格操作
    editFinContract(val) {
      this.taxDetail = Object.assign({}, val);
      // 判断是否请求
      this.viewFinContract = true;
    },
    // 取消编辑函数
    handleCancel() {
      this.viewFinContract = false;
    }
  }
};
</script>

<style scoped></style>
